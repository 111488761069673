.contain {
    background-color: white;
    border-radius: 4px;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.24);
    margin-block: 24px;
    padding: 24px;

    .title {
        font-family: 'HiraKakuPro-W3';
        font-size: 20px;
        color: rgba(0, 0, 0, 0.87);
        margin-bottom: 30px;
    }
    .table td{
       padding-block: 14px !important;
    }

    .table-secondary td{
        font-weight: 500;
    }
}