#point-chart {
	.recharts-legend-wrapper {
		@media (min-width: 320px) {
			position: absolute;
			width: 305px;
			height: auto;
			left: 50% !important;
			bottom: -10px !important;
			display: flex;
			flex-direction: row;
			justify-content: center;
			transform: translateX(-50%);
		}
		@media (min-width: 1024px) {
			position: absolute;
			width: 305px;
			height: auto;
			left: 20px !important;
			bottom: -50px !important;
			display: unset !important;
			flex-direction: unset !important;
			justify-content: unset !important;
			transform: unset !important;
		}
		@media (min-width: 1280px) {

		}
	}
	.legend-item-1 {
		margin-right: 0 !important;
	}
	.recharts-line-dot {
		fill: rgb(17, 173, 157) !important;
		r: 2 !important;
	}
	.recharts-cartesian-axis-tick-value {
		fill: rgba(148, 147, 147, 1) !important;
	}
}