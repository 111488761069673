/*box search customer management*/
.row-box-inline-CM {
	display: flex;
	padding: 8px 0 7px 0;
	//min-width: 1000px;
	.box-button-search-cm-mobile {
		width: 96%;
	}
	.close-box-search {
		width: 100%;
		text-align: center;
		padding: 5px 7px;
		color: #cbcbcb;
	}
}

.row-box-inline-CM-child {
	display: flex;
	flex-grow: 1;
}

.row-flex-box {
	display: flex;
	justify-content: space-between;
}

.form-select-sort-box-mobile {
	display: none;
	width: 100%;
	border: 1px solid rgba(0, 0, 0, 0.27);
	height: 45px;
	padding-left: 15px;
	outline: 0;
}

.row-box-inline-CM-child.text-right-row {
	justify-content: flex-start;
	align-items: flex-start;
}

.clear-data-search-mobile {
	display: block;
}

.row-box-dropdown-CM {
	display: flex;
	min-width: 360px;
}

.row-box-dropdown-CM-worries {
	display: flex;
	min-width: 800px;
}

.form-inline-row-CM {
	padding: 7px 0 0 0;
	display: flex;
	align-items: flex-start;
	.input-line-botoom-92797 {
		border: 0;
		border-bottom: 1px solid #efefef;
		margin-left: 10px;
		outline: 0;
	}

	.input-line-botoom-92797::placeholder {
		color: rgba(0, 0, 0, 0.38);
	}

	.label-text-92797 {
		padding-right: 5px;
		color: #9c97a7;
		margin-top: 4px;
		margin-left: 3px;
		margin-bottom: 0;
		input {
			margin-left: 6px;
			margin-right: 10px;
		}
	}

	.box-worries-value {
		margin-top: 3px;
		width: 250px;
		color: rgba(139, 132, 152, 1);
		margin-left: 10px;
		font-weight: bold;
	}
	.label-date-92797 {
		margin-left: 10px;
	}
	.select-line-botoom-92797 {
		color: #9c97a7;
		border: 0;
		border-bottom: 1px solid #efefef;
		border-radius: 0;
		background-color: transparent;
		min-width: 100px;
		height: 23px;
		line-height: 23px;
		max-width: 220px;
		min-height: 27px;
		outline: 0;
	}
	.select-left-line-botoom-92797 {
		color: #cacaca;
		border: 0;
		background-color: transparent;
		border-bottom: 1px solid #efefef;
		min-width: 100px;
		max-width: 220px;
		min-height: 17px;
		.css-yk16xz-control {
			min-height: 27px !important;
			.css-g1d714-ValueContainer {
				padding: 0;
			}
			.css-g1d714-ValueContainer {
			}
			.css-1hb7zxy-IndicatorsContainer {
				.css-tlfecz-indicatorContainer {
					padding: 0;
				}
			}
		}
		.css-1pahdxg-control {
			min-height: 27px;
			.css-g1d714-ValueContainer {
				padding: 0;
			}
			.css-1hb7zxy-IndicatorsContainer {
				.css-1gtu0rj-indicatorContainer {
					padding: 0;
				}
				.css-tlfecz-indicatorContainer {
					padding: 0;
				}
			}
			.css-1gtu0rj-indicatorContainer {
				padding: 0;
			}
		}
		.css-26l3qy-menu {
			margin-top: 15px;
		}
	}
	.dropdown-line-botoom-92797 {
		color: #cacaca;
		border: 0;
		background-color: transparent;
		border-bottom: 1px solid #efefef;
		margin-top: 1px;
		padding-top: 2px;
		padding-bottom: 2px;
		// width: 100%;
		position: relative;
		.dropdown-box-show {
			display: none;
			z-index: 1;
		}
	}
	.dropdown-line-botoom-92797.open {
		.dropdown-box-show {
			padding: 17px 15px 10px 8px;
			display: block;
			position: absolute;
			border: 1px solid #efefef;
			background-color: #ffffff;
		}
	}

	.dropdown-worries-botoom-92797 {
		color: #cacaca;
		border: 0;
		background-color: transparent;
		border-bottom: 1px solid #efefef;
		margin-top: 1px;
		padding-top: 2px;
		padding-bottom: 2px;
		// width: 100%;
		position: relative;
		.dropdown-box-show {
			display: none;
			z-index: 1;
		}
	}
	.dropdown-worries-botoom-92797.open {
		.dropdown-box-show {
			padding: 17px 15px 10px 8px;
			display: block;
			position: absolute;
			border: 1px solid #efefef;
			background-color: #ffffff;
		}
	}
}

.btn-search-green {
	color: #ffffff;
	background-color: #12a392;
	padding: 5px 7px 5px 7px;
	margin-left: 10px;
	margin-top: 5px;
	border: 0;
}

.btn-search-green-mobile {
	color: #ffffff;
	background-color: #12a392;
	padding: 5px 7px 5px 7px;
	border: 0;
	width: 100%;
	height: 36px;
}

/*end box search customermanagement*/
.cus-form-control-input-date {
	display: flex;
	position: relative;

	.range-input-date-92797 {
		.input-line-botoom-92797 {
			border: 0;
			border-bottom: 1px solid #efefef;
			margin-left: 10px;
		}

		.input-line-botoom-92797::placeholder {
			color: rgba(0, 0, 0, 0.38);
		}

		.form-control {
			border: 0 !important;
			box-shadow: inset 0 0 0 rgba(0, 0, 0, 0.075);
			border-bottom: 2px solid #efefef !important;
			border-radius: 0;
			// margin-left: 10px;
			width: 152px;
			height: 26px;
			padding: 0 10px 0 10px;
			font-size: 14px;
		}
		.form-control::placeholder {
			color: #cacaca;
		}
	}
	.range-input-date-92797:nth-of-type(1) {
		padding-right: 20px;
	}
	.range-input-date-92797:nth-of-type(1) ~ :after {
		content: '~';
		position: absolute;
		left: -15px;
		top: 5px;
	}
}

.frame-table-mobile {
	display: none;
}

.frame-table-mobile.hidden {
	display: none;
}

.box-clear-search-data {
	border-top: 1px solid rgba(203, 203, 203, 1);
	text-align: center;
	padding-bottom: 10px;
	padding-top: 12px;
	.tick-clear-search {
		color: rgba(203, 203, 203, 1);
		font-size: 18px;
		margin-right: 10px;
	}

	.text-title-clear-search {
		color: rgba(203, 203, 203, 1);
	}
}

.point-click {
	cursor: pointer;
}

.color-start-icon {
	color: rgba(248, 231, 28, 1);
}

.size-icon-gly {
	font-size: 18px;
}

.text-color-dropdown-green {
	color: rgba(17, 173, 157, 1);
}

.react-datepicker__input-container input {
	padding: 2px;
	border: 0;
	border-bottom: 1px solid #efefef;
	margin-left: 10px;
	outline: 0;
	background-color: transparent;
}

.react-datepicker-wrapper {
	border: 0 !important;
	width: auto;
}

.padding-box-search {
	padding-left: 20px;
}

.label-margin-right {
	margin-left: 25px !important;
}

@media only all and (max-width: 768px) {
	.frame-table-mobile {
		display: block !important;
	}

	.frame-table-mobile.hidden {
		display: none !important;
	}

	.form-select-sort-box-mobile {
		display: block;
	}

	.clear-data-search-mobile {
		display: block;
	}

	.box-clear-search-data {
		border-top: 0;
	}

	.row-box-inline-CM.row-1 {
		padding-top: 0;
	}

	.form-inline-row-CM.rowMobile {
		// display: block !important;
		padding-top: 0;
		.input-line-botoom-92797 {
			margin-left: 0;
		}
	}
	.form-inline-row-CM .input-line-botoom-92797 {
		margin-left: 0;
	}
	.row-box-dropdown-CM-worries {
		max-width: calc(100vh - 375px) !important;
		min-width: calc(100vh - 375px) !important;
		padding-left: 10px;
	}
	.row-box-dropdown-CM {
		max-width: calc(100vh - 375px) !important;
		min-width: calc(100vh - 375px) !important;
		padding-left: 10px;
	}

	.form-inline-row-CM.col-md-2 {
		width: 16.6%;
	}
	.form-inline-row-CM.col-md-3 {
		width: 33.3%;
	}
}

.label-text-92797.label-text-mobile {
	color: #9c97a7;
	margin-top: 15px;
}

.ml-title-worries {
	margin-left: 20px !important;
}

.row-back-popup {
	position: relative;
	text-align: center;
	min-height: 39px;
	line-height: 39px;
	width: 100%;
	.box-title-popup {
	}
	.arrow-back-popup {
		position: absolute;
		top: 0;
		left: 3%;
		padding: 0 10px 0 10px;
	}
}

.content-row-select {
	overflow: scroll;
	height: calc(100vh - 357px) !important;
	.row-list-select {
		display: flex;
		justify-content: space-between;
		min-height: 39px;
		line-height: 39px;
		padding-left: 20px;
		padding-right: 20px;
		border-bottom: 1px solid rgba(0, 0, 0, 0.12);
		.title-list-select {
		}
		.icon-tick-select {
			.icon-color-select-tick {
				color: #11ad9d;
				font-size: 20px;
				display: none;
			}
			.icon-color-select-tick.active {
				display: inline;
			}
		}
	}
}

.popup-list-content {
	padding: 0 !important;
}

.label-text-92797 {
	color: #9c97a7;
}

.p-box-form {
	padding-top: 0;
}
