.link-to-home-help {
	font-size: 12px;
	color: rgba(17, 173, 157, 1);
	margin: 8px 0 12px;
	display: inherit !important;
}

.primary.help-content {
	margin-top: 10px !important;
}

.box-key-word-search {
	margin-top: 10px;
	font-size: 12px;
	color: rgba(0, 0, 0, 0.38);
	padding: 15px 0 0 15px;
}

.key-search-value {
	padding: 15px 0 0 15px;
	font-weight: bold;
	font-size: 16px;
	word-break: break-all;
}

.menu-top-back-btn {
	//margin-top: 30px;
	display: none;
}

.back-link-header-help {
	margin-top: 25px;
}

@media only screen and (max-width: 796px) {
	.back-link-header-help {
		display: none;
	}
	.menu-top-back-btn {
		display: block;
	}
}

.back-to-home-mobile {
	font-size: 12px;
	color: rgb(17, 173, 157);
	margin: 8px 0 12px;
}

.primary-title__main.render-quest,
.list__item_question,
.primary__inner.render-answer {
	white-space: pre-wrap;
}

.help-content .primary__inner {
	padding: 0 15px 0 15px;
}

/*@media only screen and (max-width: 767px){
  .secondary-header {
    display: none;
  }
}*/

.box-title-header-quest {
	display: block;
	padding: 24px 0 0 18px;
	margin: 0 0;
	text-align: left;
	font-weight: normal;
	.title-quest-data {
		font-weight: bold;
		font-size: 16px;
		margin-bottom: 15px;
	}
}
