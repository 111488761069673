.make-menu-right-log-chat {
	ul {
		margin: 0;
		padding: 0;
		list-style-type: none;
		overflow-x: auto;
		white-space: nowrap;
		line-height: 40px;
		display: flex;

		li {
			padding: 6px;
			text-align: center;
			display: inline;
			width: 100%;

			a {
				text-decoration: none;
				color: #333;
				font-size: 11px;
				padding-left: 8px;
				padding-right: 8px;
			}

			a.is-active {
				color: rgb(17, 173, 157);
				position: relative;
				transition: 0.8s all;
				font-weight: bold;
			}

			a.is-active:after {
				height: 2px;
				width: 100%;
				display: block;
				content: ' ';
				bottom: -10px;
				left: 0;
				position: absolute;
				background: rgb(17, 173, 157);
			}
		}
	}
}

@media only screen and (min-width: 767px) {
	.mobile-show-answer-log {
		display: none;
	}
}

#js_chat_memo_history {
	margin-bottom: 5px;
}

.box-list-answer-chat-log.loading-list {
	.item-answer-log:last-child {
		padding-bottom: -5px;
	}
}

.box-list-answer-chat-log {
	padding: 19px 13px 10px 24px;
	overflow: auto;
	height: 85%;

	.item-answer-log {
		cursor: pointer;

		.list-category-answer-log {
			.category-item-answer {
				background-color: rgba(106, 214, 203, 0.5);
				padding: 2px 17px 2px 17px;
				border-radius: 3px;
				font-size: 12px;
				color: rgba(255, 255, 255, 1);
				margin-bottom: 10px;
			}
		}

		.detail-answer-log {
			font-size: 14px;
			color: rgba(0, 0, 0, 1);
			margin-bottom: 10px;
			letter-spacing: -1px;
			word-break: break-all;
			display: -webkit-box;
			-webkit-line-clamp: 3;
			-webkit-box-orient: vertical;
			overflow: hidden;
			white-space: normal;
		}

		.date-answer-log {
			font-size: 12px;
			color: rgba(0, 0, 0, 0.5);
			margin-bottom: 20px;
		}
	}
}

.dialog-content-body-answer {
}

.dialog-content-content-answer {
	max-width: 732px !important;
	width: 70% !important;
}

.box-detail-answer-chat {
	.header-dialog-detail-answer {
		display: flex;
		justify-content: space-between;
		margin-bottom: 19px;

		.left-header-dialog {
			color: rgba(0, 0, 0, 0.87);
		}

		.right-header-dialog {
			cursor: pointer;

			span {
				background-color: rgba(0, 0, 0, 0.12);
				padding: 3px;
			}
		}
	}

	.box-content-detail-answer {
		border: 2px solid rgba(0, 0, 0, 0.12);
		border-radius: 3px;
		padding: 18px 19px 150px 32px;
		height: auto;
		max-height: 50vh;
		overflow: auto;

		.row-first-detail-answer {
			margin-bottom: 20px;
			display: flex;
			align-items: center;

			.category-detail-answer {
				.category-item-detail-answer {
					background-color: rgba(106, 214, 203, 0.5);
					padding: 2px 17px 2px 17px;
					border-radius: 3px;
					font-size: 12px;
					color: rgba(255, 255, 255, 1);
					margin-bottom: 10px;
				}
			}

			.date-detail-answer {
				font-size: 12px;
				color: rgba(0, 0, 0, 0.5);
				margin-left: 21px;
			}
		}

		.row-body-detail-answer-user {
			font-size: 14px;
			color: rgba(0, 0, 0, 1);
			letter-spacing: -0.02em;
			padding: 0px 22px 20px 21px;
			border-bottom: 2px solid rgba(216, 216, 216, 1);
			word-break: break-all;
			white-space: normal;
		}

		.title-answer-teller {
			font-size: 12px;
			color: rgba(0, 0, 0, 0.5);
			margin-top: 15px;
			margin-bottom: 14px;
		}

		.row-body-detail-answer-teller {
			background-color: rgba(247, 243, 255, 1);
			padding: 13px 22px 26px 21px;
			font-size: 14px;
			color: rgba(0, 0, 0, 1);
			letter-spacing: -0.02em;
			word-break: break-all;
			white-space: normal;
		}
	}
}

.mobile-screen-show-chat-log {
	display: none;
}

@media screen and (max-width: 767px) {
	.mobile-screen-show-chat-log {
		display: block;
	}
}
.chat-box-customer.show_chat-box-customer {
	.btn-close-tab {
		left: 0;
	}
}

.content-detail-answer-click {
	display: none;
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 10000;
	transition: 1s all;
	margin-right: -250px;

	.box-left-menu-content-detail {
		background-color: gray;
		width: 15%;
		color: white;
		text-align: center;
		font-size: 32px;
	}
	.box-right-menu-content-detail {
		background-color: #ffffff;
		width: 85%;
		color: black;
		overflow: auto;
	}
}

.content-detail-answer-click.active-show {
	display: flex;
	margin-right: 0;
}

.box-detail-answer-chat-mobile {
	.header-dialog-detail-answer {
		display: flex;
		justify-content: space-between;
		margin-bottom: 19px;

		.left-header-dialog {
			color: rgba(0, 0, 0, 0.87);
		}

		.right-header-dialog {
			cursor: pointer;

			span {
				background-color: rgba(0, 0, 0, 0.12);
				padding: 3px;
			}
		}
	}

	.box-content-detail-answer {
		border: 2px solid rgba(0, 0, 0, 0.12);
		border-radius: 3px;
		padding: 18px 14px 150px 14px;

		.row-first-detail-answer {
			margin-bottom: 20px;
			display: flex;
			align-items: center;
			padding-left: 14px;

			.category-detail-answer {
				.category-item-detail-answer {
					background-color: rgba(106, 214, 203, 0.5);
					padding: 2px 17px 2px 17px;
					border-radius: 3px;
					font-size: 12px;
					color: rgba(255, 255, 255, 1);
					margin-bottom: 10px;
				}
			}

			.date-detail-answer {
				font-size: 12px;
				color: rgba(0, 0, 0, 0.5);
				margin-left: 21px;
			}
		}

		.row-body-detail-answer-user {
			font-size: 14px;
			color: rgba(0, 0, 0, 1);
			letter-spacing: -0.02em;
			padding: 0px 22px 20px 21px;
			border-bottom: 2px solid rgba(216, 216, 216, 1);
			word-break: break-all;
			white-space: normal;
		}

		.title-answer-teller {
			font-size: 12px;
			color: rgba(0, 0, 0, 0.5);
			margin-top: 15px;
			margin-bottom: 14px;
		}

		.row-body-detail-answer-teller {
			background-color: rgba(247, 243, 255, 1);
			padding: 13px 22px 26px 21px;
			font-size: 14px;
			color: rgba(0, 0, 0, 1);
			letter-spacing: -0.02em;
			word-break: break-all;
			white-space: normal;
		}
	}
}

.chat-karte-chart__data {
	white-space: normal;
}
