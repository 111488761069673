.btn-customer {
	// position: relative;
	padding: 7px 10px 10px 16px;
	.row-horizontal {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		.row {
			display: flex;
			flex-direction: row;
			align-items: center;
			margin-left: 0px;
	
		}
		.unread-msg-box {
			width: 22px;
			height: 22px;
			font-size: 13px;
			border-radius: 20px;
			text-decoration: none;
			background-color: rgba(236, 64, 122, 1);
			text-align: center;
			line-height: 22px;
			color: #ffffff;
			font-weight: 600;
			margin-left: 10px;
		}
		.image_pin_message {
			width: 22px;
			height: 22px;
		}
		.unread-pwa {
			position: absolute;
			right: 5%;
			bottom: 10%;
			width: 22px;
			height: 22px;
			font-size: 13px;
			border-radius: 20px;
			text-decoration: none;
			background-color: rgba(236, 64, 122, 1);
			text-align: center;
			line-height: 22px;
			color: #ffffff;
			font-weight: 600;
		}
	}


}
