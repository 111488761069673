.primary-body-in {
	padding: 0 20px 20px;
}

.item-step-in {
	padding: 8px 0;
	background-color: rgba(255, 251, 252, 1);
	display: flex;
	padding-right: 20px;
	margin-bottom: 6px;
	align-items: center;
}
.btn-step-in {
	height: 23px;
	width: 77px;
	background-color: rgba(255, 132, 173, 1);
	border-radius: 12px;
	margin: 0 20px;
}
.btn-step-in p {
	line-height: 23px;
	color: rgba(255, 255, 255, 0.87);
	text-align: center;
	font-family: 'Hiragino Kaku Gothic Pro';
	font-size: 12px;
}
.step-content-in {
	color: rgba(0, 0, 0, 0.87);
}
.step-content-in-active {
	font-weight: bold;
	color: rgba(236, 64, 122, 1);
}

.c-input-in {
	display: flex;
	align-items: center;
	padding-top: 20px;
}
.c-input-in input {
	padding: 7px 10px;
	border: 1px solid #ccc;
	min-width: 350px;
	margin-right: 20px;
}

.title-rwi {
	font-weight: bold;
	padding-bottom: 10px;
	padding-top: 20px;
	padding-left: 20px;
	font-size: 15px;
}

.item-step-rwi {
	padding: 10px 20px;
	display: flex;
	align-items: center;
	border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.step-content-rwi {
	font-weight: bold;
	padding-right: 10px;
	font-size: 15px;
}
.btn-step-rwi {
	padding: 5px 20px 5px 40px;
	border-left: 1px solid rgba(0, 0, 0, 0.12);
}

.icon-line-mb {
	width: 40px;
	height: 40px;
	overflow: hidden;
	background-image: url('../../assets/icon_LINE.png');
	background-size: 40px 40px;
}

@media (max-width: 767px) {
	.primary-body-in {
		padding: 0;
	}
	.c-input-in input {
		min-width: 270px !important;
	}
	.primary-rwi {
		padding: 0 !important;
	}
	.title-rwi {
		padding-left: 10px !important;
		padding-top: 10px;
		margin-top: 16px;
	}
	.item-step-rwi {
		padding: 10px 12px;
	}
	.btn-step-rwi {
		padding: 5px 0px 5px 12px;
		border-left: none;
	}
	.item-step-in {
		display: block;
	}
	.btn-step-in {
		margin: 0 12px;
	}
	.step-content-in {
		margin: 8px 0px 0px 12px;
	}
	.list-social {
		display: none;
	}
	.step-content-rwi {
		font-size: 14px;
	}
	.title-rwi {
		font-size: 12px;
		padding-left: 0px !important;
	}
	.tab-intro {
		width: 100%;
	}
	.tab-intro .list__item {
		width: 50%;
	}
	.tab-intro .list__item a {
		width: 100%;
		padding: 0;
	}
}
@media (max-width: 460px) {
	.c-input-in {
		align-items: flex-start !important;
		flex-direction: row;
		padding-top: 0px;
	}
	.c-input-in input {
		min-width: 200px !important;
		margin-right: 12px !important;
		margin-bottom: 10px;
	}
	.c-input-in button {
		width: 48px !important;
	}
	.bar1 span.hidden-pc {
		color: rgba(17, 173, 157, 1);
		float: right;
		margin-right: 0px;
	}
	.bar {
		padding: 12px 12px 12px 8px;
	}
	.bar2 {
		display: flex;
	}
	.bar2 span.hidden-pc {
		display: block;
		width: 32px;
		float: right;
		margin-right: 0px;
		margin-top: 8px;
	}
	.btnGetPointIntro {
		width: 100%;
	}
}
