#point-calendar {
	.rbc-header {
		padding: 0 !important;
		border-left: none !important;
	}
	.rbc-off-range-bg {
		background-color: transparent !important;
	}
	.rbc-today {
		background-color: rgba(244, 244, 244, 1) !important;
	}
	.rbc-row-content {
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}
	.rbc-month-view {
		flex: unset !important;
		height: fit-content !important;
	}
	.rbc-month-row {
		@media screen and (min-width: 320px)  {
			flex: unset !important;
			flex-basis: unset !important;
			height: 53px;
		}
		@media screen and (min-width: 1024px)  {
			height: 72px;
		}
		@media screen and (min-width: 1280px)  {
			height: 77px;
		}
	}
	.rbc-row-segment {
		direction: rtl
	}
}