.box-reward-content-reward-explanation {
	background-color: #ffffff;
	padding: 24px 20px 20px 24px;
	border-radius: 5px;
	color: #3c3c3c;
	-webkit-box-shadow: 0 0 10px #fff;
	box-shadow: 0 0 10px rgba(60, 60, 60, 0.2);

	.title-top {
		font-size: 20px;
		color: rgba(0, 0, 0, 0.87);
		margin-bottom: 24px;
	}

	.description-title-header-top {
		font-size: 15px;
		color: rgba(0, 0, 0, 0.54);
		margin-bottom: 34px;
	}

	.box-content-reward-2 {
		border: 1px solid rgba(17, 173, 157, 1);
		padding: 23px 32px 17px 32px;
		max-width: 325px;
		text-align: center;

		.header-title-reward-2 {
			font-size: 15px;
			color: rgba(0, 0, 0, 0.87);
			border-bottom: 1px solid rgba(0, 0, 0, 0.12);
			padding-bottom: 16px;
		}

		.value-title-reward-2 {
			font-size: 24px;
			color: rgba(33, 33, 33, 1);
			padding-top: 16px;
			font-weight: bold;
			word-break: break-all;
		}
	}

	.box-content-reward-3 {
		margin-top: 54px;

		.title-reward-3 {
			font-size: 15px;
			color: rgba(0, 0, 0, 0.87);
			font-weight: bold;
		}

		.table-reward-3 {
			border-collapse: separate;
			border-spacing: 0 2px;
			margin-top: 9px;
			tr {
				td:first-child {
					background-color: rgba(0, 0, 0, 0.06);
				}
				td {
					padding: 13px 15px 12px 15px;
				}

				td:last-child {
					text-align: left;
				}
			}
		}
	}

	.box-content-reward-4 {
		margin-top: 15px;
	}

	.box-content-reward-5 {
		margin-top: 15px;
		padding-bottom: 10px;
	}
}

@media only screen and (max-width: 1096px) {
	.scroll-menu-list-answer {
		.list__item {
			.btn-tab {
				padding: 0 7px 0 7px !important;
			}
		}
	}
}
