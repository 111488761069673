#dialog_validate_waiting {
  .root {
    background-color: white;
    padding: 20px 27px;
  }
  .dialog-button {
    width: 251px;
    outline: none;
    border: none;
    background: #11ad9d;
    margin-top: 5px;
    color: #fff;
    height: 44px;
    border-radius: 3px;
    margin-inline: 8px;
    margin-block: 16px;
  }
  .dialog-button[disabled] {
    width: 251px;
    outline: none;
    border: none;
    background: #e5e5e5;
    margin-top: 5px;
    color: #fff;
    height: 44px;
    border-radius: 3px;
    margin-inline: 8px;
    margin-block: 16px;
  }
  .icon_close {
    position: absolute;
    top: 26px;
    right: 4px;
    color: white;
    padding: 0px !important;
  }
}