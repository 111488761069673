.title-modal {
    color:  rgba(0, 0, 0, 0.87);
    font-size: 17px;
}

.border {
    height: 1px;
    width: 100%;
    background-color: rgba(151, 151, 151, 1);
    margin-block: 10px;
}
.info-user {
    display: flex;
    align-items: center;
}
.info-user > p,div{
    color: #000000de;
}
.info-content {
    margin-left: 15%;
}
.title-reason {
    font-size: 15px;
    color: black;
    font-weight: bold;
}
.dropdown-report {
    width: 100%;
    margin-block: 5px;
    font-size: 15px;
}
.btn-report {
    margin-top: 10px;
    border: none;
    background-color: rgba(236, 64, 122, 1);
    color: white;
    padding-block: 8px;
    padding-inline: 30px;
    font-size: 15px;
    margin-bottom: 35px;
}
.text-report {
    color: #212121 !important;
    font-size: 14px;
    line-height: 19px;
}
.text-report > span {
    color: #ec407a;
}
.text-report > a {
    color: #11ad9d;
    text-decoration: underline !important;
    font-weight: 600 !important;
}

.text-confirm {
    font-size: 17px;
    color: rgba(0, 0, 0, 0.87);
    line-height: 21px;
}
.text-confirm > span {
    color: #ec407a;
}

.btn-cancel {
    border: none;
    padding-block: 8px;
    padding-inline: 50px;
    font-size: 15px;
    margin-left: 20px;
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.24);
	overflow: hidden;
    background-color: rgba(249, 249, 249, 1);
}