.row-mobile {
	display: none;
}

@media only all and (max-width: 768px) {
	.row-pc {
		display: none !important;
	}
	.row-mobile {
		display: block !important;
	}
}

.btn-sample-voice {
	background-color: rgb(238, 60, 121);
	border: 1px solid rgb(238, 60, 121);
	padding: 7px 34px 7px 34px;
	color: #ffffff;
	font-size: 15px;
}

.btn-sample-voice-green {
	background-color: rgba(17, 173, 157, 1);
	padding: 7px 34px 7px 34px;
	color: #ffffff;
	font-size: 15px;
}

.btn-sample-voice-default {
	border: 1px solid rgba(0, 0, 0, 0.87);
	color: black;
	padding: 7px 34px 7px 34px;
	font-size: 15px;
}

.new-border-title {
	border: 1px solid rgba(236, 64, 122, 1);
	border-radius: 15px;
	padding: 2px 5px 2px 5px;
	font-size: 10px;
	width: 26px;
	height: 14px;
	text-align: center;
	color: rgba(236, 64, 122, 1) !important;
	position: absolute;
	left: 110px;
	top: 0;
}

.new-border-title-menu {
	border: 1px solid rgba(236, 64, 122, 1);
	border-radius: 15px;
	padding: 2px 5px 2px 5px;
	font-size: 10px;
	width: 26px;
	height: 14px;
	text-align: center;
	color: rgba(236, 64, 122, 1) !important;
	margin-left: 10px;
}

.title-record-implement-voice {
	margin-bottom: 14px;
	position: relative;
}

.note-warning-sample-voice {
	font-size: 16px;
	margin-top: 18px;
	color: rgba(0, 0, 0, 0.38);
}

.sound-wave-record-setting {
	display: none;
}

.box-voice-teller {
	position: relative;
	.title-box-record {
		padding: 23px 0 18px 22px;
	}

	.icon-close-modal {
		position: absolute;
		top: 0;
		right: 10px;
		color: rgba(0, 0, 0, 0.5);
		font-size: 20px;
		cursor: pointer;
	}

	.box-line-timing {
		position: relative;
		height: 22px;
		width: 86%;
		margin: auto;
		.first-line {
			position: absolute;
			background-color: rgba(248, 201, 217, 1);
			left: 0;
			height: 100%;
			width: 3px;
			border-radius: 10px;
		}
		.end-line {
			position: absolute;
			background-color: rgba(248, 201, 217, 1);
			right: 0;
			height: 100%;
			width: 3px;
			border-radius: 10px;
		}
		.process-line {
			position: absolute;
			background-color: rgba(248, 201, 217, 1);
			width: 100%;
			height: 1px;
			top: 10px;
			z-index: 0;
		}
		.middle-line {
			position: absolute;
			background-color: rgb(238, 60, 121);
			width: 0;
			height: 4px;
			top: 9px;
			z-index: 1;
		}
		.process-run-line {
			position: absolute;
			background-color: rgb(238, 60, 121);
			width: 2px;
			height: 100%;
			// left: 20%;
			z-index: 2;
		}
	}
	.box-btn-record-setting {
		width: 100%;
		text-align: center;
		padding-bottom: 10px;

		.time-run-record {
			color: rgba(236, 64, 122, 1);
			//padding-bottom: 15px;
		}

		.btn-action-run-time {
			height: 36px;
			width: 104px;
			color: #ffffff;
			// margin-bottom: 25px;
		}

		.box-background-btn-record-green {
			background-color: rgba(17, 173, 157, 1);
			border: 1px solid rgba(17, 173, 157, 1);
		}

		.box-background-btn-record-pink {
			background-color: rgba(236, 64, 122, 1);
			border: 1px solid rgba(236, 64, 122, 1);
		}

		.box-background-btn-record-default {
			border: 1px solid rgba(0, 0, 0, 0.87);
			color: black;
		}

		.row-end-record-reListen {
			display: flex;
			justify-content: space-between;
			align-items: center;
			width: 86%;
			margin: auto;
			padding-bottom: 20px;
			img {
				margin: auto 10px auto 10px;
			}
		}
	}
}

.audio-listion-replay {
	display: none;
}

.box-confirm-record {
	margin-right: 26px;
	border: 1px solid #11ad9d;
}
.box-voice-teller-warning {
	text-align: center;
}

.text-white {
	color: #9e9e9e;
}

.padding-text {
	padding: 0 16px 16px;
}
.popupBeforeGift {
	z-index: 2501 !important;
}
