.home-teller-in .bar span {
	margin-right: 20px;
}

@media (max-width: 767px) {
	.home-teller-in .bar {
		margin-top: 0 !important;
		margin-bottom: 20px !important;
	}
}
