.dl-get-point-title {
	font-family: 'Hiragino Kaku Gothic Pro';
	font-size: 20px;
	color: rgba(236, 64, 122, 1);
	text-align: center;
	font-weight: bold;
}
.dl-get-point-content {
	text-align: center;
	position: relative;
}
.dl-get-point-content img {
	width: 200px;
	height: 200px;
	object-fit: contain;
	margin: auto;
}
.dl-get-point-content .label-point {
	font-family: 'Hiragino Kaku Gothic Pro';
	font-size: 20px;
	color: rgba(236, 64, 122, 1);
	text-align: center;
	margin-top: -50px;
	font-weight: bold;
}
