.left-follower-area-row {
	display: flex;
	justify-content: space-between;
}

.btn-follower-area {
	position: absolute;
	bottom: 0;
	width: 100%;
	background-color: rgba(17, 173, 157, 1);
	padding: 10px 0 10px 0;
	text-align: center;
	color: white;
	cursor: pointer;
}

.text-new-follower-area {
	color: rgba(236, 64, 122, 1);
	margin-left: 15px;
}

.box-width-follower {
	display: block;
	padding: 0;
	margin: 0;
	background: #494958;
	height: calc(100vh - 374px) !important;
	overflow: scroll;
	overflow-x: hidden;
}

@media only all and (max-width: 768px) {
	.box-width-follower {
		height: calc(100vh - 357px) !important;
	}
}

.secondary-definition-wrap {
	position: relative;
}

.box-btn-follower-click {
	position: absolute;
	width: 100%;
	z-index: 1000;
	bottom: 0;
}
