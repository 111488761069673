@keyframes flickerAnimation {
	0% {
		opacity: 1;
	}
	50% {
		opacity: 0.5;
	}
	100% {
		opacity: 1;
	}
}

@-o-keyframes flickerAnimation {
	0% {
		opacity: 1;
	}
	50% {
		opacity: 0.5;
	}
	100% {
		opacity: 1;
	}
}

@-moz-keyframes flickerAnimation {
	0% {
		opacity: 1;
	}
	50% {
		opacity: 0.5;
	}
	100% {
		opacity: 1;
	}
}

@-webkit-keyframes flickerAnimation {
	0% {
		opacity: 1;
	}
	50% {
		opacity: 0.5;
	}
	100% {
		opacity: 1;
	}
}

.animate-flicker {
	-webkit-animation: flickerAnimation 0.5s infinite;
	-moz-animation: flickerAnimation 0.5s infinite;
	-o-animation: flickerAnimation 0.5s infinite;
	animation: flickerAnimation 0.5s infinite;
	background-color: rgb(236, 64, 122);
}

.btn-customer__item.is_comment {
	min-height: 23px;
	text-transform: initial!important; 
}
