.slick-dots li.slick-active button:before {
	color: #11ad9d;
}
.slick-dots {
	bottom: 0px;
}
.slick-slide div {
	width: 100%;
	outline: none;
}
.slick-slide img {
	width: 100%;
	max-height: 200px;
}

.singleImgContainer {
	width: 100%;
}

.singleImgContainer_img {
	width: 100%;
	height: 200px;
}

@media (max-width: 480px) {
	.slick-slider {
		margin-bottom: -42px;
	}
	.slick-dots {
		display: none !important;
	}
	.slick-slide img {
		height: 91px;
	}
	.bar {
		margin-top: 0px;
	}
}
